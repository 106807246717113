<template>
  <div class="staff-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">Staff Accounts</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="520">
        <v-text-field
          class="field46 me-4 mt-sm-0 mt-2"
          v-model="search"
          placeholder="Search for name, email"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
        <v-btn @click="modal = true" depressed width="136" large class="primary mt-sm-0 mt-2">Add new</v-btn>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openStaff(item.id)">
                <td>
                  <span :class="item.is_suspended ? 'error' : !item.is_email_confirmed ? 'warning' : 'success'" class="name-table"></span>
                  {{ item.first_name }} {{ item.last_name }}
                </td>
                <td>{{ item.email }} <v-icon v-if="!item.is_email_confirmed" color="warning">mdi-alert-circle-outline mdi-18px</v-icon></td>
                <td class="text-capitalize">{{ item.role ? item.role.split('_').join(' ') : '' }}</td>
                <td>{{ !item.is_suspended ? 'Active' : 'Inactive' }}</td>
                <td>{{ !item['2fa_enabled'] ? 'Off' : 'On' }}</td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }} staff</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openStaff(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Name</div>
              <div class="font-weight-medium f18">
                <span :class="item.is_suspended ? 'error' : !item.is_email_confirmed ? 'warning' : 'success'" class="name-table"></span>
                {{ item.first_name }} {{ item.last_name }}
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Email</div>
              <div class="font-weight-medium f18 text-truncate">
                {{ item.email }} <v-icon v-if="!item.is_email_confirmed" color="warning">mdi-alert-circle-outline mdi-18px</v-icon>
              </div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Role</div>
              <div class="font-weight-medium f18 text-capitalize">{{ item.role ? item.role.split('_').join(' ') : '' }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Status</div>
              <div class="font-weight-medium f18">{{ !item.is_suspended ? 'Active' : 'Inactive' }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">2FA</div>
              <div class="font-weight-medium f18">{{ !item['2fa_enabled'] ? 'Off' : 'On' }}</div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }} staff</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="modal" max-width="460">
      <v-card flat outlined class="pa-8 pt-5">
        <div class="text-h6 font-weight-bold mb-3">Add new Staff</div>
        <v-text-field
          class="field46"
          v-model="firstName"
          placeholder="First name"
          :error-messages="firstNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="lastName"
          placeholder="Last name"
          :error-messages="lastNameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-select
          class="field46 mt-n2"
          v-model="role"
          :items="itemsRole"
          item-text="title"
          item-value="id"
          placeholder="Role"
          :error-messages="roleErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <v-autocomplete
          v-if="role == 'trip_organizer'"
          class="field46 mt-n2"
          v-model="organizer"
          placeholder="Select trip organizer"
          :error-messages="orgErrors"
          :items="itemsOrganiz"
          item-text="organizer.name"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <v-autocomplete
          v-if="role == 'product_vendor'"
          class="field46 mt-n2"
          v-model="vendor"
          placeholder="Select product vendor"
          :error-messages="vendorErrors"
          :items="itemsVendors"
          item-text="title_en"
          item-value="id"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-autocomplete>
        <div class="text-end mt-2">
          <v-btn @click="modal = false" width="100" large text color="primary">Cancel</v-btn>
          <v-btn @click="create" class="ms-3" depressed width="176" large color="primary">Invite</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      search: this.$route.query.src || '',
      page: Number(this.$route.query.page) || 1,
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      organizer: '',
      vendor: '',
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Role', value: 'role', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: '2FA', value: '2fa_enabled', sortable: false },
      ],
      itemsRole: [
        { id: 'admin', title: 'Admin' },
        { id: 'manager', title: 'Manager' },
        { id: 'marketing', title: 'Marketing' },
        { id: 'agent', title: 'Agent' },
        { id: 'trip_organizer', title: 'Trip organizer' },
        { id: 'product_vendor', title: 'Product vendor' },
      ],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getTripOrganizers');
    this.$store.dispatch('getVendorsShort');
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openStaff(id) {
      this.$router.push(`/staff/${id}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getStaffList', {
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
    async create() {
      this.error = [];
      const data = new Object();
      data.first_name = this.firstName;
      data.last_name = this.lastName;
      data.email = this.email;
      data.role = this.role;
      this.role == 'trip_organizer' ? (data.trip_organizer = this.organizer) : '';
      this.role == 'product_vendor' ? (data.product_vendor = this.vendor) : '';
      await this.$store.dispatch('createStaff', data).catch((e) => {
        this.error = e.response.data.error;
      });
    },
  },
  watch: {
    modal() {
      this.error = [];
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.role = '';
      this.organizer = '';
    },
  },
  computed: {
    data() {
      return this.$store.getters.staffList;
    },
    itemsOrganiz() {
      return this.$store.getters.tripOrganiz;
    },
    itemsVendors() {
      return this.$store.getters.vendorsShort;
    },
    firstNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    roleErrors() {
      const errors = [];
      this.error.find((item) => item == 'role__required') && errors.push('Please enter role');
      this.error.find((item) => item == 'role__invalid') && errors.push('Provided role is not valid');
      return errors;
    },
    orgErrors() {
      const errors = [];
      this.error.find((item) => item == 'organizer__required') && errors.push('Please select trip organizer');
      this.error.find((item) => item == 'organizer__invalid') && errors.push('Selected trip organizer is not valid');
      return errors;
    },
    vendorErrors() {
      const errors = [];
      this.error.find((item) => item == 'product_vendor__required') && errors.push('Please select product vendor');
      this.error.find((item) => item == 'product_vendor__invalid') && errors.push('Selected product vendor is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setStaffList', {});
    this.$store.dispatch('setTripsItem');
  },
};
</script>

<style lang="scss">
.staff-list {
  .name-table {
    position: relative;
    bottom: 2px;
  }
  .name-table {
    position: relative;
    top: 5px;
    margin-right: 12px;
    display: inline-block;
    width: 4px;
    height: 20px;
    border-radius: 5px;
  }
  table tbody tr:hover td:first-of-type {
    color: var(--v-primary-base) !important;
  }
}
</style>
